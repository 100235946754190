import React from "react";
import "./App.css";

function App() {
  const redirectToRundleChalet = () => {
    window.open(
      "https://www.rundlechalet.com/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="App">
      <div className="App-content">
        <h1>BanffChalet</h1>
        <div className="description-box">
          <h2>Coming Soon</h2>
          <p>
            The journey to the majestic BanffChalet begins here. While we are
            curating the perfect alpine escape at our banff chalets, take a
            moment to explore our sister site, RundleChalets. We're crafting
            experiences that encapsulate the essence of Banff's natural beauty
            at each Banff chalet. Join our newsletter or bookmark this site to
            be the first to know when we go live.
          </p>
          <button onClick={redirectToRundleChalet}>Explore RundleChalet</button>
        </div>
      </div>
    </div>
  );
}

export default App;
